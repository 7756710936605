<template>
  <div class="pmp-ads">
    <Teleport to="#header-buttons">
      <v-select
        v-model="currentState"
        class="mr-1 w-m-20"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="value"
        :clearable="false"
        :searchable="false"
        :options="currentStateOptions"
      />
      <div>
        <b-button
          variant="primary"
          :disabled="pmpAdsLoading"
          @click="addPmpAds"
        >
          Add PMP Ad
        </b-button>
      </div>
    </Teleport>
    <div>
      <app-collapse
        id="faq-payment-qna"
        accordion
        type="margin"
      >
        <b-skeleton-wrapper :loading="pmpAdsLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <div
            v-if="allPmpAds.length === 0"
            class="text-center mt-5 font-large-1 text-black-50"
          >No Ads Found</div>
          <PmpAdsCollapseItem
            v-for="(pmpAds, index) in allPmpAds"
            :id="pmpAds.id"
            :key="index"
            :approved="pmpAds.approved_by"
            :title="pmpAds.name"
            :time="pmpAds.time"
            :pmp-ad="pmpAds"
            icon="EditIcon"
            :src="null"
            @editPmpAds="editPmpAds"
          >
            <div class="heading-elements">
              <b-row>
                <b-col
                  md="8"
                  class="mx-auto"
                >
                  <b-card class="mr-1 mb-1 adds-views-class">
                    <div class="d-block font-weight-bolder text-nowrap stats">
                      Ad Views
                    </div>
                    <div class="demo-vertical-spacing">
                      <div class="stats-div">
                        <label class="labels">Views</label>
                        <label class="float-right labels">{{
                          currentState.value === "Past"
                            ? pmpAds.pmp_ad_view_count
                            : "--"
                        }}</label>
                      </div>
                      <div class="stats-div">
                        <label class="labels">Qualified Views</label>
                        <label class="float-right labels">{{
                          currentState.value === "Past"
                            ? pmpAds.qualified_view_count
                            : "--"
                        }}</label>
                      </div>
                      <div class="stats-div">
                        <label class="labels">Cost Per Qualified View</label>
                        <label class="float-right labels">{{
                          "$" + perViewCost
                        }}</label>
                      </div>
                    </div>
                    <div class="d-block cost-class">
                      Total Cost
                      <label class="float-right labels">{{
                        currentState.value === "Past"
                          ? "$" + pmpAds.qualified_view_count * perViewCost
                          : "--"
                      }}</label>
                    </div>
                  </b-card>
                </b-col>
                <!-- Column: Devices -->
                <b-col md="12">
                  <div class="device">
                    Devices
                  </div>
                  <div
                    v-if="!pmpAds.length"
                    style="text-align: center; font-size: 20px!important;"
                    class="font-weight-normal mb-2"
                  >
                    This information will only be available after ad is played successfully
                  </div>
                </b-col>

              </b-row>
            </div>
          </PmpAdsCollapseItem>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
    <div
      v-if="allPmpAds.length"
      class="mt-1"
    >
      <CustomPagination
        :total="paginationObject.total"
        :per-page="paginationObject.limit"
        :page="paginationObject.offset"
        @perPageChanged="perPageChanged"
        @pageChanged="pageChanged"
      />
    </div>
  </div>
</template>
<script>
import {
  BSkeletonWrapper,
  BSkeleton,
  VBTooltip,
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import Teleport from 'vue2-teleport'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import PmpAdsCollapseItem from '@/components/pmp-ads/PmpAdsCallapseItem.vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile } from '@/common/global/functions'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import TimeLimitCondition from '@/common/config'
import CustomPagination from '@/components/common/CustomPagination.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  name: 'Index',
  components: {
    CustomPagination,
    PmpAdsCollapseItem,
    AppCollapse,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    Teleport,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](
      MemberPermissions.PMP_ADS,
    )
    const isUserAdmin = store.getters['user/getUserRole']
    if (memberPermission || (isUserAdmin === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  data() {
    return {
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      currentState: { key: 1, value: 'Future' },
      timeState: 'future',
      MediaType,
      pmpAdsLoading: false,
      id: '',
      allPmpAds: [],
      selectMode: 'single',
      perViewCost: TimeLimitCondition.costPerView,
      currentStateOptions: [
        { key: 1, value: 'Future' },
        { key: 2, value: 'Past' },
      ],
      groupID: this.$route.params.userId,
    }
  },
  watch: {
    currentState: {
      handler(value) {
        if (value.value === 'Past') {
          this.timeState = 'past'
        } else {
          this.timeState = 'future'
        }
        this.pageChanged(1)
      },
    },
  },
  mounted() {
    this.getAllPmpAds(this.paginationObject)
  },
  methods: {
    convertLocation(location) {
      const [lng, lat] = location?.split('(')?.[1].split(' ')
      return `lng = ${lng}, lat = ${lat.replace(')', '')}`
    },
    addPmpAds() {
      this.$router.push(`/users/${this.$route.params.userId}/user-pmp-ads/create-ads`)
    },
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllPmpAds(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllPmpAds(this.paginationObject)
    },
    async getAllPmpAds({ offset, limit }) {
      this.pmpAdsLoading = true
      const response = await this.$store.dispatch('pmpAds/getAllUserPmpCampaignsByAdmin', { groupId: this.groupID, limit, offset: offset - 1 })
      if (response) {
        this.allPmpAds = response.results
        this.paginationObject.total = response.total
      }
      this.pmpAdsLoading = false
    },
    editPmpAds(obj) {
      this.$router.push(`/users/${this.$route.params.userId}/user-pmp-ads/edit-ads/${obj.id}`)
    },
    async onRowSelected(items, index) {
      if (items[0] && items[0].media) {
        const mediaArray = Object.assign([], [items[0].media])
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      await this.$refs.mediaTable[index].clearSelected()
    },
  },
}
</script>

    <style lang="scss">
    .pmp-ads {
      #time-state-select {
        .vs__selected-options {
          min-width: 100px !important;
        }
        .vs__dropdown-menu {
          min-width: 100px !important;
        }
        .vs__dropdown-toggle {
          min-width: 100px !important;
          background-color: white !important;
        }
      }
      .vs__dropdown-toggle {
        background-color: white !important;
      }
      #per-page-selector {
        .vs--single .vs__dropdown-toggle {
          max-height: 37px !important;
        }
        .vs__dropdown-toggle {
          min-width: 90px !important;
        }
      }
      .image {
        width: 32px;
        height: 32px;
        border: 2px solid#ececec;
        border-radius: 5px !important;
        box-shadow: 0 1px 7px#0000002e;
      }
      .demo-vertical-spacing {
        margin-bottom: 5px;
      }
      .switches {
        border-radius: 25px;
      }
      .labels {
        font-size: 14px;
        letter-spacing: 0px;
        color: #414141;
        font-weight: 500;
        font-family: "Montserrat";
        line-height: 2;
      }
      .stats-div {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .stats {
        font-size: 14px;
        letter-spacing: 0px;
        color: #1f58b5;
        font-weight: 600;
        font-family: "Montserrat";
      }
      .media-card {
        max-height: 225px !important;
        min-height: 225px !important;
      }
      .adds-views-class {
        min-height: 225px;
      }
      .per-page-selector {
        width: 90px;
      }
      .cost-class {
        font-size: 14px;
        letter-spacing: 0px;
        color: #414141;
        font-weight: 600;
        font-family: "Montserrat";
      }
      .top-margin-class {
        margin-top: -3.5rem;
        @media (max-width: 848px) {
          margin-top: 0px !important;
        }
      }
    }
    </style>
